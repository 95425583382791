<template>
  <div class="animation_main">
    <!-- ANIMATION CATALOG -->
    <transition name="panel-slide">
      <AnimationChoice v-click-outside="closePanel" class="panel" panel-position="50" style="top: -9em;"
        v-if="show_catalog == true" @selectAnim="animation_selected_from_catalog"
        :anim_type_filter="anim_filter==-1?undefined:anim_filter" :format="filter_format">
      </AnimationChoice>
    </transition>

    <div class="flexRow center" style="width: 95%;  height: 100%; align-items: flex-start">
      <!-- ANIMATION PREVIEW -->
      <div style="width: 40%">
        <input type="file" ref="json_input" @change="fileUploaded($event, true)" style="display: none" />
        <div style="display:inline-flex;">
          <animationViewer class="animation" :animation="animationByFormat('horizontal')"
            :key="animationByFormat('horizontal').anim_data"
            :noAnimation="animationByFormat('horizontal').anim_features == undefined" format="horizontal"
            style="width: 50%;" @click="clickAnimation = 'horizontal', $refs.json_input.click()" />

          <animationViewer class="animation" :animation="animationByFormat('vertical')"
            :key="animationByFormat('vertical').anim_data"
            :noAnimation="animationByFormat('vertical').anim_features == undefined" format="vertical"
            style="width: 20%;" @click="clickAnimation = 'vertical', $refs.json_input.click()" />

          <animationViewer class="animation" :animation="animationByFormat('square')"
            :key="animationByFormat('square').anim_data"
            :noAnimation="animationByFormat('square').anim_features == undefined" format="square" style="width: 30%;"
            @click="clickAnimation = 'square', $refs.json_input.click()" />
        </div>
        <div v-if="animationsComplete.length == 0"
          style="display: flex;flex-direction: column ;width: fit-content;margin: auto;">
          <h3 style="margin-top: 5px;">Charger une animation</h3>
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <div style="display: flex;align-items: center;">

              <a @click.stop="show_catalog = true" class="center openJson" style="float: right;">Choisir
                depuis de
                catalogue</a>
              <select v-model="filter_format">
                <option value="horizontal">horizontal</option>
                <option value="vertical">vertical</option>
                <option value="square">square</option>
              </select>
              <select v-model="anim_filter">
                <option value=-1>Tous</option>
                <option value=0>Intro</option>
                <option value=2>Outro</option>
                <option value=1>Titre</option>
              </select>
            </div>
          </div>
        </div>

        <br>
        <div v-if="animationsComplete.length != 0" style="width: 100%;">
          <h3>Identité graphique</h3>
          <IdentityContent v-model="identity" :key="identity"></IdentityContent>
          <label>Text</label>
          <textarea v-model="text"></textarea>
          <p class="button" button-type="secondary" style="margin-right: 20px" @click="restore_default_identity">
            Identité par defaut
          </p>
          <p class="button" button-type="primary " style="margin-right: 20px" @click="applyIdentity">
            Appliquer
          </p>
        </div>
      </div>

      <!-- ANIMATION PARAMETERS -->
      <div v-if="animationsComplete.length != 0" class="anim_parameters">
        <h1>Informations principales</h1>
        <br>
        <div class="flexRow" style="justify-content: space-between; margin-bottom: 20px">

          <div style="width: 60%">
            <label>Nom de l'animation</label>
            <input type="text" v-model="anim_info.anim_name" style="width: 100%" />
          </div>
          <div style="width: 38%">
            <label>Type d'animation</label>
            <select v-model="anim_info.anim_type" style="width: 100%">
              <option></option>
              <option value="0">Intro</option>
              <option value="2">Outro</option>
              <option value="1">Titre</option>
            </select>
          </div>
          <p class="button" @click="saveAnimation">
            <span v-if="anim_info._id != undefined">Enregister</span>
            <span v-else>Ajouter</span>
          </p>
          <p class="button" @click="resetForm">Annuler</p>

        </div>

        <div style="display: flex;justify-content: space-between;">

          <inputList v-if="data_iptList" :key="anim_info" @change_listUser="change_listUser" :original_list="filteredUsers"
            :current_list="usr_select" :original_exclu="false" :value="anim_info.anim_exclu"
            @input="(e) => anim_info.anim_exclu = e"></inputList>
          <p v-if="anim_info._id != undefined" class="button" button-type="primary"
            style="height: fit-content;background-color: red;" @click="deleteAnims">Supprimer</p>
        </div>
        <br>
        <h1>Paramètres des animations</h1>

        <div style="display: inline-flex;">
          <h4 style="margin: auto 0px;">Orientation : </h4>
          <select name="" id="" v-model="featureSelected">
            <option v-for="elem in features_available" :key="elem" :value="elem">{{ elem }}</option>
          </select>
        </div>
        <div>
          <h3>Animation data</h3>
          <div style="display: flex; justify-content: space-around;">
            <p class="openJson" @click="downloadJson">Télécharger</p>
            <input type="file" ref="json_input_data" @change="fileUploaded($event, false)" style="display: none" />
            <p class="openJson" @click="$refs.json_input_data.click()">Remplacer</p>
            <p class="openJson" v-if="animationsComplete.length > 1" style="color:red" @click="deleteAnim">Supprimer</p>
          </div>
        </div>
        <!-- FEATURES -->
        <br>
        <h3>Features</h3>
        <section class="feature">



          <div v-for="(feature, featureIndex) in animationByFormat(featureSelected).anim_features"
            :key="feature.anim_name" style="margin-bottom: 20px">
            <p style="font-weight: 600">Feature {{ featureIndex + 1 }}</p>

            <!-- input -->
            <div class="flexRow" style="justify-content: space-between">
              <div>
                <label>Nom de la feature</label>
                <input type="text" v-model="feature.name" style="width: 200px" />
              </div>
              <div>
                <label>Type d'input</label>
                <select v-model="feature.user_input" style="width: 200px">
                  <option value="color">color</option>
                  <option value="image">image</option>
                  <option value="text">text</option>
                  <option value="font">font</option>
                </select>
              </div>

              <img src="../../../../assets/trash.png" class="poubelle" @click="deleteFeature(featureIndex)" />
            </div>
            <p>Properties</p>
            <div>
              <div v-for="(propertie, propertieIndex) in feature.properties" :key="propertie" class="properties flexRow"
                style="justify-content: space-between">
                <div>
                  <label>Nom de la propertie</label>
                  <input type="text" v-model="propertie.name" />
                </div>
                <div>
                  <label>Json location</label>
                  <input type="text" v-model="propertie.location" style="width: 250px"
                    placeholder="/anim_data/layers/0/ef/1/ef/0/v/k" />
                </div>
                <div v-if="feature.user_input == 'color'">
                  <label>Color index</label>
                  <!-- <input type="text" v-model="propertie.color_index" style="width: 80px"/> -->
                  <select v-model="propertie.color_index" style="width: 120px">
                    <option></option>
                    <option value="0">Primaire</option>
                    <option value="1">Secondaire</option>
                    <option value="2">Text</option>
                  </select>
                </div>
                <div style="width: 30%">
                  <label>Description</label>
                  <input type="text" v-model="propertie.description" style="width: 100%" />
                </div>

                <img src="../../../../assets/trash.png" class="poubelle"
                  @click="deleteProperty(featureIndex, propertieIndex)" />
              </div>
              <p class="button" style="float: right; margin-top: 20px" @click="addPropertie(featureIndex)">
                Ajouter une propertie
              </p>
            </div>
            <hr />
          </div>
        </section>
        <p class="button" @click="addFeature">Ajouter une feature</p>
      </div>
    </div>
  </div>
</template>

<script>
//LIBS
import axios from "axios";
//CUSTOMS LIBS
import documentHeight from "../../../../lib/documentHeight";
// COMPONENT
import animationViewer from "../../../../components/Object/animationViewer";
import AnimationChoice from "../../../../components/Panels/AnimationChoice/AnimationChoice.vue"
import inputList from "../../../../components/Object/inputList";
import IdentityContent from "../../../../components/Panels/IdentityChoice/IdentityModifier/identityContent/identityContent.vue"
//STORE
import store from "../../../../store";
import Vuex from "vuex";
//FILES
import logo_placeholder from "../../../../assets/logo_placeholder.jpg"   //"../../assets/logo_placeholder.jpg";
//FILE READER
const fr = new FileReader();

export default {
  name: "Admin.animation",
  store: store,
  data: function () {
    return {
      anim_info: {},
      animations: [
        { anim_format: 'horizontal', anim_data: undefined, anim_features: undefined },
        { anim_format: 'vertical', anim_data: undefined, anim_features: undefined },
        { anim_format: 'square', anim_data: undefined, anim_features: undefined }
      ],

      animToDelete: [],
      clickAnimation: null,
      featureSelected: "",
      show_catalog: false,
      users: [],
      filteredUsers: [],
      data_iptList: false,
      usr_select: [],
      identity: {
        _id: null,
        ident_name: "",
        ident_colors: [],
        ident_logo: null,
        ident_baseline: "",
        ident_font: null,
        ident_intro: null,
        ident_title: null,
        ident_outro: null,
      },
      panelOpen: false,
      default_font_name: "Poppins-SemiBold",
      anim_filter: 0,
      filter_format: "horizontal",
      text : "Yopbox"
    };
  },
  components: {
    animationViewer,
    AnimationChoice,
    inputList,
    IdentityContent,
  },
  mounted() {
  
    axios.get("/users").then((res) => {
      this.users = res.data;
      this.data_iptList = true;
    });
  },
  computed: {
    panelHeight: function () {
      return documentHeight() - 33.33 - 60 - 40 + "px";
    },
    animationHorizontal() {
      let index = this.animations.findIndex((a) => a.anim_format == "horizontal")
      if (index != -1) {
        return this.animations[index]
      } else {
        return undefined
      }
    },
    animationVertical() {
      let index = this.animations.findIndex((a) => a.anim_format == "vertical")
      if (index != -1) {
        return this.animations[index]
      } else {
        return undefined
      }
    },
    animationSquare() {
      let index = this.animations.findIndex((a) => a.anim_format == "square")
      if (index != -1) {
        return this.animations[index]
      } else {
        return undefined
      }
    },
    features_available() {
      let result = []
      this.animations.forEach(element => {
        if (element.anim_features != undefined) {
          result.push(element.anim_format)
        }
      });
      return result
    },
    animationsComplete() {
      return this.animations.filter(a => a.anim_data != undefined)
    }
  },
  methods: {
    ...Vuex.mapActions([
      "get_animation",
    ]),
    animationByFormat(format) {
      let index = this.animations.findIndex((a) => a.anim_format == format)
      if (index != -1) {
        return this.animations[index]
      } else {
        return { anim_features: undefined }
      }
    },
    setAnimation(anim) {
      let index = this.animations.findIndex((a) => a.anim_format == anim.anim_format)
      if (index != -1) {
        Object.keys(anim).forEach(key => {
          this.animations[index][key] = anim[key]
        });
      }
    },
    applyIdentity() {
      return this.transformTobase64(this.identity.uploaded_logo_file)
        .then((base64) => {

          this.animationsComplete.forEach(anim => {
            var animSave = JSON.parse(JSON.stringify(anim))
            this.setAnimation({ anim_data: undefined, anim_format: anim.anim_format })
            this.buildAnim({
              anim: animSave,
              text: this.text,
              identity: {
                ident_logo: base64,
                ident_font: this.identity.ident_font,
                ident_colors: this.identity.ident_colors,
              }
            })
              .then((res) => {
                this.setAnimation({ ...res.data, anim_format: animSave.anim_format })
              })
          });

        })
    },
    restore_default_identity() {
      this.identity = {
        _id: null,
        ident_name: "",
        ident_colors: ["#4fbecc", "#0c2444", "#ffffff"],
        ident_logo: logo_placeholder,
        ident_baseline: "Yopbox Productions",
        ident_font: null,
        ident_intro: null,
        ident_title: null,
        ident_outro: null,
      },
        fetch(logo_placeholder)
          .then((response) => response.blob())
          .then((myBlob) => {
            this.identity.uploaded_logo_file = myBlob

          });
    },
    change_listUser(list) {
      this.anim_info.anim_usrExclu = list;

      if (
        list.length > 0 &&
        (this.anim_info.anim_exclu == false ||
          this.anim_info.anim_exclu == undefined)
      ) {
        this.anim_info.anim_exclu = true;
      }
    },
    readJsonFile(file) {
      return new Promise((resolve, reject) => {
        fr.onload = (e) => {
          resolve(JSON.parse(e.target.result));
        };
        fr.onerror = function () {
          console.error("Failed to read file !", fr.error);
          fr.abort();
          reject(fr.error);
        };
        fr.readAsText(file);
      });
    },
    fileUploaded(evt, analyseFeature = true) {
      var promise = undefined
      if (analyseFeature) {
        let form = new FormData();
        form.append("file", evt.target.files[0]);
        promise = axios
          .post("animations/features", form, {
            contentType: "multipart/form-data",
          })
          .then((res) => {
            return Promise.resolve(res.data);
          })
          .catch(() => {
            //If feature analyse is impossible via api, just reading json file locally
            return this.readJsonFile(evt.target.files[0]).then((json) => {
              return Promise.resolve({
                anim_data: json,
                anim_features: [],
              });
            });
          })
      } else {
        promise = this.readJsonFile(evt.target.files[0]).then((json) => {
          return Promise.resolve({
            anim_data: json
          });
        });
      }
      promise
        .then((animation_object) => {
          this.$refs.json_input.value = ""
          if (this.anim_info.anim_name == "") {
            this.anim_info.anim_name = animation_object.anim_name
          }
          delete animation_object.anim_name
          if (analyseFeature) {
            this.setAnimation({ ...animation_object, anim_format: this.clickAnimation })
            this.featureSelected = this.clickAnimation
          } else {
            this.setAnimation({ ...animation_object, anim_format: this.featureSelected })
          }


          this.restore_default_identity()
        });
    },
    addFeature() {
      let indexFormat = this.animations.findIndex((a) => a.anim_format == this.featureSelected)
      if (indexFormat != -1) {
        this.animations[indexFormat].anim_features.push({
          name: "",
          user_input: 0,
          properties: [],
        });
        var index = this.animations[indexFormat].anim_features.length;
        this.addPropertie(index - 1);
      }
    },
    addPropertie(featureIndex) {
      let indexFormat = this.animations.findIndex((a) => a.anim_format == this.featureSelected)
      if (indexFormat != -1) {
        this.animations[indexFormat].anim_features[featureIndex].properties.push({
          name: "",
          location: "",
          color_index: "",
          description: "",
        });
      }
    },
    deleteFeature(featureIndex) {
      let indexFormat = this.animations.findIndex((a) => a.anim_format == this.featureSelected)
      if (indexFormat != -1) {
        this.animations[indexFormat].anim_features.splice(featureIndex, 1);
      }
    },
    deleteProperty(featureIndex, propertieIndex) {
      let indexFormat = this.animations.findIndex((a) => a.anim_format == this.featureSelected)
      if (indexFormat != -1) {
        this.animations[indexFormat].anim_features[featureIndex].splice(
          propertieIndex,
          1
        );
      }
    },
    change_feature(event, featureIndex) {
      let indexFormat = this.animations.findIndex((a) => a.anim_format == this.featureSelected)
      if (indexFormat != -1) {
        this.animations[indexFormat].anim_features[featureIndex].value =
          event.target.value || event.target.url;
      }

    },
    animation_selected_from_catalog(animation) {
      this.usr_select = [];

      axios.get('/animations/group/' + animation.animGrp_id)
        .then((res) => {
          this.featureSelected = res.data.anim[0].anim_format
          if (res.data.anim_usrExclu != undefined) {
            for (let id of res.data.anim_usrExclu) {
              this.usr_select.push(this.users.filter((u) => u._id == id)[0]);
              this.filteredUsers = this.users.filter((u) => u._id != id);
            }
          }
          res.data.anim.forEach(anim => {
            this.setAnimation(anim)
          });
          this.$nextTick(() => {
            delete res.data.anim
            this.anim_info = res.data
          })

        })
      this.show_catalog = false;
      this.restore_default_identity()

    },
    saveAnimation() {
      if (this.anim_info.anim_type == undefined) {
        this.Toaster.warn('Animation type non défini')
        return
      }

      if (this.anim_info.anim_name == undefined || this.anim_info.anim_name == "") {
        this.Toaster.warn('Animation name non défini')
        return
      }

      if (this.anim_info._id != undefined) {
        let promiseList = []
        this.animations.forEach(anim => {
          if (anim.anim_data != undefined) {

            if (anim._id != undefined) {
              promiseList.push(axios
                .put('/animations/' + anim._id, { ...this.anim_info, ...anim }))
            } else {
              let body = { ...this.anim_info, ...anim, animGrp_id: this.anim_info._id }
              delete body._id
              promiseList.push(axios
                .post('/animations/', body))
            }
          }
        });
        Promise.all(promiseList)
          .then(() => {
            if (this.animToDelete.length > 0) {
              this.animToDelete.forEach(id => {
                this.deleteAnimOnApi(id)
              });
            }

            this.Toaster.success("Animation saved")
            this.resetForm()
          })
          .catch((err) => {
            this.Toaster.error("Error during save")
            console.error(err);
          });
      } else {

        axios.post('/animations', { ...this.anim_info, ...this.animationsComplete[0] })
          .then((res) => {
            let promiseList = []
            this.animationsComplete.forEach((anim, idx) => {
              if (idx != 0) {
                promiseList.push(axios.post('/animations', { ...this.anim_info, ...anim, animGrp_id: res.data.animGrp_id }))
              } else {
                promiseList.push(null)
              }
            });
            Promise.all(promiseList)
              .then(() => {
                this.Toaster.success("Animation created")
                this.resetForm()
              })
              .catch((err) => {
                console.error(err)
                this.Toaster.error("Error during anim creation")
              })
          })
      }
    },
    resetForm() {
      this.anim_info = {}
      this.animations = [
        { anim_format: 'horizontal', anim_data: undefined },
        { anim_format: 'vertical', anim_data: undefined },
        { anim_format: 'square', anim_data: undefined }
      ]
      this.animToDelete = []
      this.animation_id = null
      // this.data_iptList = false
      this.usr_select = []
      this.identity = {
        _id: null,
        ident_name: "",
        ident_colors: [],
        ident_logo: logo_placeholder,
        ident_baseline: "",
        ident_font: null,
        ident_intro: null,
        ident_title: null,
        ident_outro: null,
      }
      this.anim_filter = 0
      this.filter_format = "horizontal"
      this.restore_default_identity()
      this.filteredUsers = this.users
    },
    closePanel() {
      if (this.show_catalog) {
        this.show_catalog = false
      }

    },
    transformTobase64(file) {
      return new Promise((resolve) => {
        if (file != undefined) {
          var reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            resolve(reader.result)
          };
        } else {
          resolve(undefined)
        }
      })
    },
    deleteAnim() {
      let anim = this.animationByFormat(this.featureSelected)

      this.$nextTick(() => {
        if (anim._id != undefined) {
          this.animToDelete.push(anim._id)
        }
        let index = this.animations.findIndex((a) => a.anim_format == anim.anim_format)
        this.animations[index] = { anim_format: anim.anim_format, anim_data: undefined, anim_features: undefined }
        //  }
        this.featureSelected = this.features_available[0]
      })


    },
    deleteAnimOnApi(id) {
      return axios.delete('/animations/' + id)
        .then(() => {
          var animDeleted = this.animations.filter(a => a._id == id)[0]
          this.$nextTick(() => {
            this.animations = this.animations.filter(a => a._id != id)

            this.animations.push(
              { anim_format: animDeleted.anim_format, anim_data: undefined, anim_features: undefined }
            )
            return Promise.resolve()
          })

        })
        .catch((e) => {
          console.error(e)
          this.Toaster.error('Error during delete animation')
        })
    },
    deleteAnims() {
      let animIds = this.animations.filter(a => a._id != undefined).map(a => { return a._id })

      this.deleteAnimOnApi(animIds[0])
        .then(() => {
          if (animIds.length == 1) {
            this.Toaster.success('Animation deleted')
            this.resetForm()
          } else {
            this.animations = this.animations.filter(a => a._id != animIds[0])
            this.$nextTick(() => {
              this.deleteAnims()
            })
          }
        })

    },
    downloadJson() {
      let indexFormat = this.animations.findIndex((a) => a.anim_format == this.featureSelected)
      let data = JSON.parse(JSON.stringify(this.animations[indexFormat].anim_data))

      for (let idx = 0; idx < data.assets.length; idx++) {
        data.assets[idx].p = "";
      }
      const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = this.anim_info.anim_name != undefined ? this.anim_info.anim_name : ""
      link.download += "-" + this.featureSelected
      link.click()
    },
    buildAnim(set) {
      return axios.post('/animations/build', set)
    }
  },
};
</script>



<style src="../../../../styles/panel.css" scoped>

</style>

<style src="./style.css" scoped>

</style>
