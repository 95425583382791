<template >
  <div>
    <section class="inline">
      <div  class="inline">
        <label  for="">Exclu :</label>
        <input v-model="exclu" @input="handleExclu_bool" type="checkbox" />
      </div>
      <div  class="inline">
        <label for="">User exclu :</label>
        <input type="text" list="list" @change="new_elem($event)" />
      </div>
      
    </section>

    <datalist id="list">
      <option
        v-for="usr in list"
        
        :key="usr"
        :value="usr.usr_firstname + ' ' + usr.usr_lastname"
      ></option>
    </datalist>
    <section>
      <div class="wrap">
        <p>List :</p>
        <div >
          <div v-for="usr in selected" :key="usr" class="inline">
            <p>{{ usr.usr_firstname }} {{ usr.usr_lastname }}</p>
            <button class="button" @click="del_elem(usr)">X</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "inputLisr",
  props: ["original_list", "current_list", "value"],
  emits: ["change_listUser", "input"],

  data() {
    return {
      loadData: false,
      list: [],
      selected: [],
      exclu: this.value
    };
  },
  mounted() {
    this.load();
  },
  
  methods: {
    load() {
      
      this.list = this.original_list;
      this.selected = this.current_list;
      this.loadData = true;
      
    },
    new_elem(event) {
      if(!this.exclu)
      {
        this.exclu = true
      }
      if (event.target.value != "") {
        let fullname = event.target.value;
        let nameSplit = fullname.split(" ");

        let usr = this.list.filter(
          (u) =>
            u.usr_firstname == nameSplit[0] && u.usr_lastname == nameSplit[1]
        )[0];
        this.selected.push(usr);
        this.list = this.list.filter(
          (u) =>
            u.usr_firstname != nameSplit[0] && u.usr_lastname != nameSplit[1]
        );

        event.target.value = "";

        let idUsers = [];
        for (let usr of this.selected) {
          idUsers.push(usr._id);
        }
        this.$emit("change_listUser", idUsers);
      }
    },
    del_elem(usr) {
      this.selected = this.selected.filter((u) => u._id != usr._id);
      this.list.push(usr);

      let idUsers = [];
      for (let usr of this.selected) {
        idUsers.push(usr._id);
      }
      this.$emit("change_listUser", idUsers);
      //this.temp.back_usrExclu = this.temp.back_usrExclu.filter(u => u != usr._id)
    },
    handleExclu_bool () {
     
      if (this.exclu == false || this.exclu == undefined)
      {
        this.exclu = true
      }else{
        this.exclu = false
      }
  
      this.$emit('input', this.exclu)
    }
  }
};
</script>
    


<style scoped src="@/styles/buttons.scss" lang="scss">
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#img_block {
  display: block;
  width: 170px;
  height: 100px;
}
#img {
  max-width: 170px;
  max-height: 140px;
}
.inline {
  display: inline-flex;
  
}


input{
  display: block;
  font-size:1vw;
}

label{
  display: block;
  width: 6vw;
  margin: 0;
  padding: 0;
}



</style>
